import React from 'react';
import { Row, Form, Input, Button, Checkbox } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import { colors } from 'constants/index';

import 'antd/dist/antd.css';

const LoginView = ({ onFinish, setRoute }) => {
  return (
    <Form
      name='normal_login'
      className='login-form'
      initialValues={{ remember: true }}
      onFinish={onFinish}
    >
      <Form.Item
        name='username'
        rules={[{ required: true, message: 'Please input your Username!' }]}
      >
        <Input
          prefix={
            <UserOutlined
              style={{ color: colors.primary }}
              className='site-form-item-icon'
            />
          }
          placeholder='Username'
          autoComplete='username'
        />
      </Form.Item>
      <Form.Item
        name='password'
        rules={[{ required: true, message: 'Please input your Password!' }]}
      >
        <Input
          prefix={
            <LockOutlined
              style={{ color: colors.primary }}
              className='site-form-item-icon'
            />
          }
          type='password'
          placeholder='Password'
          autoComplete='current-password'
        />
      </Form.Item>
      <Form.Item>
        <Row justify='space-between'>
          <Form.Item name='remember' valuePropName='checked' noStyle>
            <Checkbox>Remember me</Checkbox>
          </Form.Item>

          <Button
            type='link'
            style={{ color: colors.primary }}
            onClick={() => {
              setRoute('Step1');
            }}
          >
            Forgot password
          </Button>
          {/* <a className="login-form-forgot" href="/">
            
          </a> */}
        </Row>
      </Form.Item>

      <Form.Item>
        <Button
          type='primary'
          htmlType='submit'
          className='login-form-button'
          style={{ width: '100%' }}
        >
          Log in
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LoginView;
