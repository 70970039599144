import { createSlice } from "@reduxjs/toolkit"

const userSlice = createSlice({
  name: "user",
  initialState: {
    username: "",
    email: "",
    company: "",
    phone: "",
    department: "",
    duty: "",
    industry: "",
    path: "",
    userStamp: "",
    tutorial: true,
    token: "",
  },
  reducers: {
    setUser: (state, action) => {
      state.id = action.payload.id
      state.username = action.payload.username
      state.email = action.payload.email
      state.company = action.payload.company
      state.phone = action.payload.phone
      state.department = action.payload.department
      state.duty = action.payload.duty
      state.industry = action.payload.industry
      state.path = action.payload.path
      state.userStamp = action.payload.userStamp
      state.tutorial = action.payload.tutorial
      state.token = action.payload.token
    },
    initUser: (state, action) => {
      state.username = ""
      state.email = ""
      state.company = ""
      state.phone = ""
      state.department = ""
      state.duty = ""
      state.industry = ""
      state.path = ""
      state.userStamp = ""
      state.tutorial = true
      state.token = ""
    },
  },
})

export const name = userSlice.name
export const reducer = userSlice.reducer
export const actions = {
  ...userSlice.actions,
}
