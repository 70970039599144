import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import moment from "moment"

import createStore from "./store"
import App from "./App"
import { PersistGate } from "redux-persist/integration/react"
import { persistStore } from "redux-persist"

import "moment/locale/ko"

moment.locale("ko")

const store = createStore()
let persistor = persistStore(store)

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,

  document.getElementById("root")
)
