import axios from "axios"

export const colors = {
  primary: "#E3006A",
}

export const strapi = axios.create({
  baseURL: "https://api.rittal-exhibition.co.kr/",
  // baseURL: "http://localhost:1337/",
})

export const API_URL = "https://api.rittal-exhibition.co.kr"
// export const API_URL = "http://localhost:1337"
