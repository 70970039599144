import styled, { css } from 'styled-components';
import { Row, Col } from 'antd';
import { colors } from 'constants/index';

export const AdminFormContainer = styled.div`
  padding: 1px 25px;
  margin: auto;

  background: white;
`;
export const FormHeader = styled.h2`
  font-weight: bold;
  padding-top: 18px;
  padding-bottom: 15px;
  magin: 0 !important;
`;

export const LoginPage = styled(Row)`
.ant-btn-primary {
  background: ${colors.primary};
  border-color: ${colors.primary};
}
a {
  color: ${colors.primary};
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: ${colors.primary};
  border -color: ${colors.primary};
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border: 1px solid ${colors.primary} !important;
}
.ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper {
  box-shadow: none;
  
}
.ant-input-affix-wrapper:focus, 
.ant-input-affix-wrapper-focused, 
.ant-input-affix-wrapper:hover {
  border-color: ${colors.primary};
}
`;

export const ModalBody = styled(Col)`
  padding: 20px;
`;
export const Title = styled.h2`
  text-align: center;

  padding-bottom: 15px;
  border-bottom: 1px solid #d9d9d9;
`;

export const BottomContents = styled(Row)`
  margin-bottom: 20px;
`;

export const UserListArea = styled(Col)`
  margin-top: ${(props) => (props.mobile === 'true' ? '15px' : '0px')};
  h4 {
    margin-bottom: 0;
  }
  .ant-list-item-meta {
    padding: 13px 20px 13px 13px;
    background-color: #fafafa;
  }
  .ant-list-split .ant-list-item {
    border-bottom: 0;
    padding: 4px 0;
  }
  .ant-badge {
    position: absolute;
    top: 14px;
    right: 6px;
  }
`;
export const UserInfoSubTitle = styled(Title)`
  border-left: 2px solid ${colors.primary};
  padding-left: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
`;
export const SearchBox = styled(Row)`
  margin-top: 12px;
  .ant-input-affix-wrapper:hover,
  .ant-input-affix-wrapper {
    border-top: 0;
    border-right: 0;
    border-left: 0;
    box-shadow: none;
  }
`;
export const ListUserID = styled.span`
  font-size: 14px;
  font-weight: bold;
`;
export const ListDescription = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const ListDate = styled.div`
  color: rgba(0, 0, 0, 0.45);
  font-size: 10px;
  position: absolute;
  right: 25px;
  top: 8px;
`;
export const MessageList = styled(Col)`
  height: 600px;
  overflow-y: scroll;
  margin-top: 15px;
`;
export const ChattingBoxHeader = styled(Col)`
  padding: 20px;
  border-bottom: 1px solid #e9e9e9;
`;
export const UserInfo = styled(ChattingBoxHeader)`
  border: 0;
  height: ${(props) => props.mobile || '696px'};
`;
export const MessageBox = styled(Col)`
  ${(props) =>
    props.isadmin === 'true'
      ? css`
          background: ${colors.primary};
          color: white;
        `
      : css`
          background: #e9e9e9;
          color: black;
        `}

  padding: 20px;
  position: relative;
  margin: 25px 0;
`;
export const MessageFrom = styled.span`
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  position: absolute;
  top: -20px;
  left: 0px;
  color: #595959;
`;
export const ChatRoom = styled(Col)`
  height: 572px;
  overflow-y: scroll;
  padding: 30px;
`;
export const MessageWhen = styled.span`
  color: rgba(0, 0, 0, 0.45);
  font-size: 10px;
  position: absolute;
  bottom: 0;
  right: -55px;
  color: #595959;
`;

export const UserInformation = styled(Col)`
  font-size: 14px;
  .ant-row {
    margin-bottom: 12px;
  }
`;
