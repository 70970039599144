import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import { strapi } from 'constants/index';
import { useHistory } from 'react-router-dom';
import { actions } from 'features/user/slice';
import 'antd/dist/antd.css';
import { useDispatch, useSelector } from 'react-redux';
import LoginView from './LoginView';
import logoImg from 'assets/img/logo-rittal.png';
import { Row, Col, Space } from 'antd';
import { LoginPage, AdminFormContainer, FormHeader } from 'assets/style';
import Step1 from './forgotPassword/Step1';
import Step2 from './forgotPassword/Step2';
import Step3 from './forgotPassword/Step3';

const { setUser } = actions;

const LogIn = () => {
  const pageData = {
    login: { height: 310, header: 'Log in to your account' },
    Step1: { height: 350, header: '비밀번호 찾기' },
    Step2: { height: 410, header: '비밀번호 찾기' },
    Step3: { height: 230, header: '비밀번호 찾기' },
  };
  const [route, setRoute] = useState('login');
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  useEffect(() => {
    const { token } = user;
    if (token) {
      history.push('/');
    }
    // eslint-disable-next-line
  }, []);
  const onFinish = ({ username, password }) => {
    (async () => {
      try {
        const { data } = await strapi.post('/auth/local', {
          identifier: username,
          password,
        });

        if (!data.user.isAdmin) {
          message.error('이메일과 비밀번호를 확인해주세요.');
          return;
        }

        const token = data.jwt;
        dispatch(setUser({ token, ...data.user }));

        history.push('/');
      } catch (error) {
        console.log(error);
        message.error('이메일과 비밀번호를 확인해주세요.');
      }
    })();
  };
  const routeOnFrontPage = () => {
    switch (route) {
      case 'Step1':
        return <Step1 setRoute={setRoute} />;
      case 'Step2':
        return <Step2 setRoute={setRoute} />;
      case 'Step3':
        return <Step3 setRoute={setRoute} />;

      default:
        return <LoginView onFinish={onFinish} setRoute={setRoute} />;
    }
  };
  return (
    <LoginPage
      type='flex'
      justify='center'
      style={{ height: '100vh', background: `#F0F2F5` }}
    >
      <Col xs={20} sm={20} lg={12} xl={10} style={{ paddingTop: 100 }}>
        <div style={{ textAlign: 'center' }}>
          <Space size={20} direction='vertical'>
            <Col span={6} style={{ margin: 'auto', width: '25%' }} offset={18}>
              <img src={logoImg} alt='' style={{ width: '100%' }} />
            </Col>
            <p>
              Holisticly incubate global models and an expanded array of
              e-tailers. Continually maximize prospective testing procedures
              with reliable communities.
            </p>
            <Row justify='center'>
              <Col xs={24} lg={18}>
                <AdminFormContainer
                  style={{
                    transition: 'all 0.3s',
                    maxHeight: 700,
                    height: pageData[route].height,
                  }}
                >
                  <FormHeader>{pageData[route].header}</FormHeader>
                  {routeOnFrontPage()}
                </AdminFormContainer>
              </Col>
            </Row>
          </Space>
        </div>
      </Col>
    </LoginPage>
  );
};

export default LogIn;
